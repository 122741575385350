import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import ConcernVideo from "../../components/concern/ConcernVideo";
import ConcernProducts from "../../components/concern/ConcernProducts";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { ApiConcernParam as ApiParamKey } from "../../api/network/apiParams";
import { fetchConcernDetail } from "../../api/redux/concern";
import SlideReverse from "../../components/concern/SlideReverse";
import AboutComponentReverse from "../../components/concern/AboutComponentReverse";

const ConsernComponent = ({ offset }) => {
  const dispatch = useDispatch();
  let { slug } = useParams();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.PRODUCT_DATA;

    let Api_Concern_Param = {
      [ApiParamKey.type]: "slug",
      [ApiParamKey.value]: slug,
      [ApiParamKey.image]: "yes",
      [ApiParamKey.post]: "yes",
      [ApiParamKey.specification]: "no",
      [ApiParamKey.file]: "no",
      [ApiParamKey.gallery]: "no",
      [ApiParamKey.variation]: "no",
    };

    dispatch(fetchConcernDetail([api_url, Api_Concern_Param]));
  }, [slug]);

  const getData = useSelector((state) => state.concern);

  const concernBanner = getData?.detail?.data;
  const concernOverview = getData?.detail?.data?.posts?.list?.find(
    (f) => f?.data?.template === "concern_overview"
  );
  const concernActivity = getData?.detail?.data?.posts?.list?.find(
    (f) => f?.data?.template === "concern_activity"
  );
  const concernVideo = getData?.detail?.data?.posts?.list?.find(
    (f) => f?.data?.template === "concern_video"
  );
  const concernProduct = getData?.detail?.data?.posts?.list?.find(
    (f) => f?.data?.template === "concern_product"
  );

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {concernBanner?.product_data?.title
            ? concernBanner?.product_data?.title + " | Lanco Group"
            : "Concern | Lanco Group"}{" "}
        </title>
        <meta name="description" content="Description" />
      </Helmet>

      <StyledComponent>
        <InnerBanner
          title={concernBanner?.product_data?.title}
          img={concernBanner?.images?.list?.[0]?.full_path}
          text={concernBanner?.product_data?.description}
        />

        <AboutComponentReverse reverse data={concernOverview} offset={offset} />
        <SlideReverse data={concernActivity} reverse  offset={offset} />
        {concernVideo && <ConcernVideo offset={offset} data={concernVideo} />}
        {concernProduct && <ConcernProducts data={concernProduct} />}
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section``;

export default ConsernComponent;
