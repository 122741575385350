import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import TeamList from "../../components/about/TeamList";
import JoinUs from "../../components/about/JoinUs";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {fetchTeam} from "../../api/redux/team";
import GetInTouch from "../../components/GetInTouch";

const MyComponent = ({offset}) => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'our-team',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchTeam([api_url, api_params]))
    }, [])



    //Refactor Data

    const getData = useSelector((state) => state.team);

    const teamBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-team-banner');
    const teamList = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'team-list');
    const joinUs = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'team-video');


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Our Team | Lanco Group</title>
                <meta name="description"
                      content="Lanco Group's commitment to excellence, integrity, and sustainability sets it apart as a visionary conglomerate."/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={teamBanner?.section_data?.subtitle}
                             img={teamBanner?.images?.list?.[0]?.full_path}
                             text={teamBanner?.section_data?.description}/>

                <TeamList data={teamList}/>
                <JoinUs offset={offset} data={joinUs}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
