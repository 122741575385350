import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import UpdatesListing from "../../components/updates/UpdatesListing";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import update, {fetchUpdate, fetchUpdateDetail} from "../../api/redux/update";
import GetInTouch from "../../components/GetInTouch";

const MyComponent = () => {
    const [designation, setDesignation] = useState('');
    const [loading, setLoading] = useState(false);


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'updates',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
            [ApiParamKey.category_slug]: '',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchUpdate([api_url, api_params]))

        let api_for_listing = apiEndPoints.ALLUPDATES
        dispath(fetchUpdateDetail([api_for_listing]))

    }, [])


    const getData = useSelector((state) => state.update);

    const bannerData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'updates-banner');
    const updateList = getData?.detail?.data;



    const filterCategory = getData?.posts;
    const list = getData?.posts?.update_list;




    const filteredCategoryName = filterCategory?.update_cat

    // let optionsOne = getData?.detail?.data?.map((data, index) => ({
    //     value: data?.data?.category_slug,
    //     label: data?.data?.category_title
    // }));

    let optionsOne = getData?.detail?.data?.map((data, index) => ({
        value: data?.data?.category_slug,
        label: data?.data?.category_title
    })).filter((item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );


    console.log('list', optionsOne)


    let newLabel = {
        "value": 0,
        "label": "All"
    };

    if (Array.isArray(optionsOne)) {
        optionsOne.push(newLabel);
    } else {
        optionsOne = [newLabel]; // Initialize labelList with the new label
    }

    const filteredData = list?.filter((item, index) => item?.data?.category_slug === designation)

    let handleLocation = (value) => {
        setDesignation(value);
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Updates | Lanco Group</title>
                <meta name="description"
                      content="Lanco Group's commitment to excellence, integrity, and sustainability sets it apart as a visionary conglomerate."/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={bannerData?.section_data?.subtitle}
                             img={bannerData?.images?.list?.[0]?.full_path}
                             text={bannerData?.section_data?.description}/>
                <UpdatesListing show handleLocation={handleLocation} category={optionsOne} data={filteredData} list={list} />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
