import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Container>
                {
                    data?.map((item) => {
                        console.log(item)
                        return (
                            <Row className={'single-director'}>
                                <Col className={'director-image'} sm={5}>
                                    <div className="director-image__wrap">
                                        <Img src={item?.images?.[0]?.full_path}/>
                                    </div>
                                </Col>
                                <Col className={'director-content'} sm={{offset: 1, span: 6}}>
                                    <h5>{item?.data?.title}</h5>
                                    <h6>{item?.data?.subtitle} </h6>
                                    <p>{reactHtmlParser(item?.data?.description)}</p>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 150px 0;
  background: #fff;

  .single-director {
    padding-bottom: 120px;
    
    &:nth-last-of-type(1) {
      padding-bottom: unset;
    }
  }

  .single-director:nth-of-type(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
    .offset-sm-1 {
      margin-left: unset;
    } 
  }

  .single-director:nth-last-of-type(1) {
    padding-bottom: unset;
  }

  .director-image {
    &__wrap {
      position: relative;
      padding-top: calc(470 / 470 * 100%);
    }
  }

  .director-content {
    h5 {
      color: #000000;
    }

    h6 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 30px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0);
    }
  }

    @media(max-width: 991px){
        .col-sm-5{
            max-width: 100%;
            flex: 0 0 100%;
        }
        .col-sm-6{
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0;
            margin-top: 30px;
        }

        .director-image {
            &__wrap {
                position: relative;
                padding-top: calc(370 / 470 * 100%);
            }
        }
    }
    
  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding: 80px 0;
    .single-director {
      padding-bottom: 30px;
      &:nth-of-type(even) {
        flex-direction: column-reverse;
      }
    }
    .single-director:nth-of-type(even) {
      flex-direction: unset;
    }
    .director-content {
      padding-top: 30px;  
    }
  }
`;

export default MyComponent;
