import React, {useEffect, useRef, useState} from "react";
import Select, {components} from "react-select";
import {hover} from "../../styles/globalStyleVars";
import {BsChevronDown, BsCircleFill, BsX} from "react-icons/bs";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import Title from "../Title";
import {Link, Router, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import ReactHtmlParser from "react-html-parser";
import {postForm} from "../../api/redux/contact";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : '#221F1F',
        backgroundColor: state.isSelected ? '#000000' : '#FFF',
        margin: 0,
        fontSize: 14,
        cursor: 'pointer',
        paddingLeft: 15
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,
        borderRadius: 5,
        fontSize: 12,

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000000' : '#FFF',
        borderRadius: 0,
        fontWeight: '400',
        color: '#FFF',
        fontSize: 12,

    }),

};


const IndustrialLube = ({queryParam, data, loc, accordionData, jobCategory, jobData}) => {

    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [categoryId, setCategoryId] = useState(1);


    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [selectedJobTitle, setSelectedJobTitle] = useState('');

    const handlePopup = (jobTitle) => {
        setSelectedJobTitle(jobTitle);
        // document.document.body.classList.add('popup');
        setShow(true);
        // Logic for handling the popup
    };

    const [selectedFileName, setSelectedFileName] = useState('');
    const [cv, setCv] = useState(null)
    const cvRef = useRef()

    const handleInputcv = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
            setCv(file); // Update the cv state with the selected file
        }
    };

    // const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(null);


    let handleMobileSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {/*<FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>*/}
                    {/*<img src={props.selectProps.menuIsOpen ? caretUpImg : caretDownImg } alt=""/>*/}

                    <svg xmlns="http://www.w3.org/2000/svg" width="10.086" height="5.751" viewBox="0 0 10.086 5.751">
                        <g id="Group_4565" data-name="Group 4565" transform="translate(0.707 0.707)">
                            <path id="Path_1882" data-name="Path 1882" d="M0,0H6.132"
                                  transform="translate(8.672 0) rotate(135)" fill="none" stroke="#161f13"
                                  stroke-linecap="round" stroke-width="1"/>
                            <path id="Path_1883" data-name="Path 1883" d="M0,0H6.132"
                                  transform="translate(4.336 4.336) rotate(-135)" fill="none" stroke="#161f13"
                                  stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>


                </components.DropdownIndicator>
            )
        );
    };
//Form submission

    const dispatch = useDispatch();
    // const responseData = useSelector(state => state.contact);
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CAREERFORM;


        var formData = new FormData();
        formData.append('full_name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('cover_letter', e?.message);
        formData.append('file', cv);

        formData.append('form_id', 'career-form');


        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted');
            reset();
            setSelectedFileName(''); // Clear the selected file name
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    // let filterPostBySlug = selectedLocation === '' ? data : data?.filter(element => element?.office_location === selectedLocation);

    // console.log(filterPostBySlug);

    let optionsOne = jobCategory?.map((data, index) => ({
        value: data?.id,
        label: data.title
    }));

    const handleCategoryActive = (id) => {
        setCategoryId(id);
    }
    const filteredArrayByCategoryId = jobData?.filter(item => item?.data?.category_id === categoryId || item?.data?.category_id === selectedOption);
    const getCategoryCount = (categoryId) => {
        return jobData?.filter(item => item?.data?.category_id === categoryId).length;
    };

    return (
        <StyledFaq className="job-lists pb-200">
            <Container>
                <Row>
                    <Col sm={12} className={'career-title'}>
                        <h3>{data?.section_data?.subtitle}</h3>
                    </Col>

                    <Col sm={4} className='job-lists__sidebar'>
                        <div className="job-lists__sidebar__mobile-dropdown">
                            <Select onChange={location => handleMobileSelect(location.value)} styles={customStyles}
                                    components={{DropdownIndicator}}
                                    className='select-here files'
                                    placeholder={'Select'}
                                    options={optionsOne}/>
                        </div>

                        <div className="desktop-lists">
                            {/*<ul>*/}
                            {/*    {*/}
                            {/*        jobCategory?.map((item) => {*/}
                            {/*            return (*/}
                            {/*                <li className={categoryId === item?.id ? "active" : ""}*/}
                            {/*                    onClick={() => handleCategoryActive(item?.id)} key={item?.id}>*/}
                            {/*                    <a className={categoryId === item?.id ? "active" : ""}>*/}
                            {/*                        {reactHtmlParser(item?.title)} */}
                            {/*                    </a></li>*/}
                            {/*            )*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</ul>*/}
                            <ul>
                                {
                                    jobCategory?.map((item) => {
                                        const postCount = getCategoryCount(item?.id);
                                        return (
                                            <li className={categoryId === item?.id ? "active" : ""}
                                                onClick={() => handleCategoryActive(item?.id)} key={item?.id}>
                                                <a className={categoryId === item?.id ? "active" : ""}>
                                                    {reactHtmlParser(item?.title)} ({postCount})
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Col>


                    <Col sm={8} className='job-lists__content'>
                        {
                            filteredArrayByCategoryId ? (
                                <Accordion defaultActiveKey="0">
                                    {
                                        filteredArrayByCategoryId?.map((item, index) => {
                                            return (
                                                <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header>
                                                        <div className={''}>
                                                            <h6>{ReactHtmlParser(item?.data?.title)}</h6>
                                                        </div>

                                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52"
                                             viewBox="0 0 52 52">
                                                <g id="Animation_-_Nav" data-name="Animation - Nav"
                                                   transform="translate(52) rotate(90)">
                                                    <circle id="Ellipse_4377" data-name="Ellipse 437" cx="26" cy="26"
                                                            r="25"
                                                            fill="transparent"></circle>
                                                    {/*<circle id="Ellipse_4378" data-name="Ellipse 437" cx="26" cy="26"*/}
                                                    {/*        r="26"*/}
                                                    {/*        fill="#FFFDFB "></circle>*/}
                                                    <g id="Group_15984" data-name="Group 15984"
                                                       transform="translate(-97 -5894.5)">
                                                        <line id="Line_3865" data-name="Line 3865" x2="5" y2="5"
                                                              transform="translate(120.5 5915.5)" fill="none"
                                                              stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3866" data-name="Line 3866" y1="5" x2="5"
                                                              transform="translate(120.5 5920.5)" fill="none"
                                                              stroke="#181d24" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </g>
                                            </svg>
                                    </span>

                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Job Description</p>
                                                        <p>{ReactHtmlParser(item?.data?.description)}</p>

                                                        <div className="button-group d-flex"
                                                             onClick={() => handlePopup(item?.data?.title)}>
                                                            <Button fontWeight={400} fontSize={16} letterSpacing={'0'}
                                                                    text={'Apply'}/>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            ) : 'not found'
                        }

                    </Col>

                </Row>


            </Container>

            <Modal show={show} className='apply-modal' onHide={handleClose}>
                <Modal.Body>
                    <div className="modal-data">
                        <Container fluid>
                            <Row>
                                <Col md={{span: 11, offset: 1}} className={'p-0'}>
                                    <div onClick={handleClose} className="modal-close dc-btn">
                                        <div className="title">
                                            <h4>{reactHtmlParser(selectedJobTitle)}</h4>
                                        </div>

                                        <div className="button">
                                            <svg id="Button_-_Close" data-name="Button - Close"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="45" height="45" viewBox="0 0 70 70">
                                                <g id="Ellipse_10" data-name="Ellipse 10"
                                                   transform="translate(70 70) rotate(180)"
                                                   fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                                    <circle id={'circle-border'} cx="35" cy="35" r="33" stroke="#000000"
                                                            stroke-width="1"/>
                                                    <circle id={'hover-color'} cx="35" cy="35" r="34.5" fill="none"
                                                            stroke-width="0"/>
                                                </g>
                                                <circle id="Ellipse_470" data-name="Ellipse 470" cx="2" cy="2" r="2"
                                                        transform="translate(37 37) rotate(180)" fill="#fffdfb"
                                                        opacity="0"/>
                                                <g id="Group_1433" data-name="Group 1433"
                                                   transform="translate(-107 -18)">
                                                    <line id="Line_4" data-name="Line 4" x2="24.021"
                                                          transform="translate(133.515 44.515) rotate(45)" fill="none"
                                                          stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_3" data-name="Line 3" x2="24.021"
                                                          transform="translate(150.485 44.5) rotate(135)" fill="none"
                                                          stroke="#000000" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={{span: 7, offset: 1}} className='modal-data__content p-0'>
                                    <Form onSubmit={handleSubmit(onSubmit, onError)}>

                                        <Form.Control
                                            className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("name", {
                                                required: 'Username is required',
                                                pattern: {
                                                    value: /^[A-Za-z]+$/,
                                                    message: 'Username must contain only letters',
                                                },

                                            })}
                                            type="text" autoComplete="off" placeholder="Full Name *"/>
                                        {/*<div className="form__phoneEmail">*/}
                                        <Form.Control
                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: 'please enter your phone first'
                                                },
                                                pattern: {
                                                    value: /^01[0-9]{9}$/,
                                                    message: 'please enter a valid 11 digit phone number'
                                                }
                                            })}
                                            type="number" placeholder="Phone Number*" autoComplete="off"/>
                                        <Form.Control
                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: 'please enter your email'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'please enter a valid email address'
                                                }
                                            })}
                                            type="email" placeholder="Email*" autoComplete="off"/>
                                        {/*</div>*/}
                                        <Form.Control
                                            className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("message", {
                                                required: {
                                                    // value:true,
                                                    message: 'please enter your message'
                                                },

                                            })}
                                            type="textarea" placeholder="Message" autoComplete="off"/>

                                        <div className="button-group">
                                            <div className="attachCvName">
                                                <div className="attach-cv">
                                                    <div className="attach-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             viewBox="0 0 16 16">
                                                            <g id="Group_22502" data-name="Group 22502"
                                                               transform="translate(-133.895 -717.5)">
                                                                <path id="Path_1748" data-name="Path 1748"
                                                                      d="M744.85,1223.491l-3.321,3.321a3.1,3.1,0,0,0,0,4.384h0a3.1,3.1,0,0,0,4.384,0l3.321-3.321"
                                                                      transform="translate(-606.226 -499.105)"
                                                                      fill="none" stroke="#f7f3f0"
                                                                      stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="1"/>
                                                                <path id="Path_1749" data-name="Path 1749"
                                                                      d="M760.654,1216.6l3.222-3.222a3.1,3.1,0,0,0,0-4.384h0a3.1,3.1,0,0,0-4.384,0l-3.222,3.222"
                                                                      transform="translate(-615.39 -490.081)"
                                                                      fill="none" stroke="#f7f3f0"
                                                                      stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="1"/>
                                                                <line id="Line_67" data-name="Line 67" y1="3.818"
                                                                      x2="3.818" transform="translate(139.986 723.591)"
                                                                      fill="none" stroke="#f7f3f0"
                                                                      stroke-linecap="round" stroke-linejoin="round"
                                                                      stroke-width="1"/>
                                                            </g>
                                                        </svg>
                                                    </div>

                                                    <Form.Control
                                                        ref={cvRef}
                                                        {...register('cv')}
                                                        type="file"
                                                        accept=".pdf"
                                                        id="resume-upload"
                                                        style={{display: "none"}}
                                                        onChange={handleInputcv}
                                                    />
                                                    <Form.Label htmlFor="resume-upload" className="resume-upload-label"
                                                                style={{display: selectedFileName ? "none" : "block"}}>
                                                        Attach CV
                                                    </Form.Label>
                                                    {selectedFileName && (
                                                        <div className="file-name">
                                                            {selectedFileName.length > 10 ? (
                                                                <>
                                                                    {selectedFileName.substring(0, 10)}...{selectedFileName.split('.').pop()}
                                                                </>
                                                            ) : (
                                                                selectedFileName
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <Button onClick={handleSubmit(onSubmit, onError)} text={'Submit'}/>
                                            </div>
                                        </div>
                                    </Form>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
    min-height: 70vh;
    background-color: #F7F3F0;


    .career-title {
        margin-bottom: 60px;
    }


    .apply-modal .modal-data__content .form-control::placeholder {
        font-size: 12px;
    }
    

    a:hover {
        color: rgba(22, 31, 19, 0.2) !important;
    }

    .job-lists__sidebar {

        &__mobile-dropdown {
            @media (min-width: 551px) {
                display: none;
            }

            img {
                margin: auto;
            }
        }

        ul {
            @media (max-width: 550px) {
                display: none;
            }

            li {
                margin-bottom: 10px;

                a {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 400;
                    color: #000000;
                    font-family: Helvetica;
                    position: relative;
                    //background: red;

                    &:after {
                        content: '';
                        position: absolute;
                        border-radius: 50%;
                        top: 6px;
                        bottom: 0;
                        right: -20px;
                        margin: auto;
                        height: 10px;
                        width: 10px;
                        background-color: ${hover};
                        display: none;
                    }

                }

                &.active {
                    a {
                        color: #000000;

                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .desktop-lists ul li.active {
        color: #000000;
    }

    .desktop-lists ul li a {
        color: rgba(28, 55, 0, 0.5);
        cursor: pointer;

        &:hover {
            color: #000000 !important;
        }
    }

    .desktop-lists ul li.active a {
        color: #000000;
    }


    .accordion-item {
        margin-bottom: 40px;

        h2 {
            font-size: unset;
            line-height: unset;
            font-weight: unset;
        }

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }

    .accordion {
        opacity: 1 !important;
        transform: translate(0px, 0px) !important;
        transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);
    }

    .accordion-header {
        position: relative;
        margin: 0;
        transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);

        button {
            border: none;
            background-color: transparent;
            padding-left: 0;
            color: #000000;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            //text-transform: capitalize;
            padding-bottom: 15px;
            border-bottom: 1px solid rgb(22, 31, 19, .2);
            width: 100%;
            text-align: left;
            //margin-bottom: 50px;
            transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);

            h4 {
                color: #000000;
                padding-right: 30px;
            }

            span {
                svg {
                    transform: rotate(180deg);

                    #Ellipse_4377 {
                        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                        fill: transparent;
                        stroke: #000000;
                    }

                    #Line_3865 {
                        stroke: #000000;
                        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                    }

                    #Line_3866 {
                        stroke: #000000;
                        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                    }

                    &:hover {
                        #Ellipse_4378 {
                            r: 26;
                        }

                        #Line_3865 {
                            stroke: #FFFFFF;
                        }

                        #Line_3866 {
                            stroke: #FFFFFF;
                        }
                    }
                }
            }

            &.collapsed {
                //color: #FFFDFB;
                border-color: rgb(22, 31, 19, .2);
                transition: height 0.7s cubic-bezier(0.4, 0, 0, 1);


                span {
                    //background-color: red;

                    svg {
                        transform: rotate(0deg);


                        #Line_3865 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            stroke: #181d24;

                        }

                        #Line_3866 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            stroke: #181d24;
                        }

                        #Ellipse_4378 {
                            r: 26;
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

                        }

                        #Ellipse_4377 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            fill: transparent;
                            stroke: #000000;
                        }

                        &:hover {

                            #Ellipse_4378 {
                                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                                r: 0;
                            }

                            #Line_3865 {
                                stroke: #FFFDFB;
                            }

                            #Line_3866 {
                                stroke: #FFFDFB;
                            }

                            #Ellipse_4377 {
                            }
                        }
                    }

                    // &:after {
                        //   background-color: ${hover} !important;
                    // }
                }
            }
        }

        span {
            position: absolute;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            color: #ffffff;
            background-color: transparent;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 16px;

            &:after {
                height: 0;
                width: 0;
                background-color: ${hover};
                border-radius: 50%;
                opacity: 0;
                transition: all .4s ease;
                content: '';
                position: absolute;
            }

            svg {
                font-size: 13px;
                z-index: 2;
                transform: rotate(180deg);
            }

        }

        &:hover {
            span {
                svg {
                    //background: red;

                    g {
                        line {
                            stroke: #ffffff !important;
                        }
                    }
                }

                &:after {
                    height: 100%;
                    width: 100%;
                    opacity: 1;
                }
            }

            button {
                    // color: ${hover};
                    // border-color: ${hover};
            }
        }
    }

    .accordion-body {
        padding: 40px 0;
        transition: max-height 0.3s ease-in-out;

        p {
            margin: 20px 0;

            &:nth-of-type(1) {
                font-weight: bold;
            }
        }

    }

    .button-group {
        margin-top: 40px;
    }


    .css-13cymwt-control, .css-t3ipsp-control {
        border-radius: 30px !important;
        border-color: #000000;
        height: 43px;
        background-color: transparent;
        cursor: pointer;
        padding-left: 15px;
    }

    .css-oyvj4z-menu {
        z-index: 999;
    }


    .css-1u9des2-indicatorSeparator {
        visibility: hidden;
    }

    .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
        padding-right: 25px !important;
    }

    .accordion-header button.collapsed {
        border-color: #000000;
    }

    .accordion-header button {
        border-color: #000000;
    }

    .css-13cymwt-control .css-1wa3eu0-placeholder, .css-13cymwt-control .css-1wa3eu0-placeholder, .css-1dimb5e-singleValue {
        //color: #ffffff !important;
        font-size: 14px !important;
        font-weight: 400;
        font-family: Helvetica;
        cursor: pointer;
        color: #000000;
    }

    .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
        margin-right: 20px;
        margin-top: 2px;
    }


    @media (max-width: 767px) {
        overflow: visible;
        padding-bottom: 120px !important;
        .container {
            overflow: visible;
        }

        .career-title {
            margin-bottom: 80px;
        }

        .job-lists__sidebar {
            margin-bottom: 50px;

            p {
                width: 100%;
            }
        }
    }

`;
export default IndustrialLube;

