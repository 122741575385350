import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

const AboutComponentReverse = ({offset, data, black, showBtn}) => {
    return (
        <StyledComponent black={black} offset={offset} >
            <Row>

                <Col className={'pr-0'} lg={{span: 6}} md={12}>
                    <div className="image">
                        <Img src={data?.images?.[0]?.full_path} />
                    </div>
                </Col>
                <Col lg={{span: 5, offset: 1}} className={'text-wrap'} md={12} >
                    <div className="text">
                        <h3>{data?.data?.title}</h3>
                        <p>{ReactHtmlParser(data?.data?.description)}</p>
                        {
                            !showBtn && data?.data?.weblink && <Button isBlank src={data?.data?.weblink} text={'Explore'} hoverBackground={black ? '#FFFFFF' : ''} color={black ? '#FFFFFF' : ''}
                                                background={'transparent'} hoverColor={ black ? '#000000' : ''} stroke={black ? '#000000' : ''}
                                                borderColor={ black ? '#FFFFFF' : ''}  />
                        }

                    </div>
                </Col>
            </Row>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: ${props => props.black ? '#1C1C1C' : '#fff'} ;
  .text-wrap {
      padding-top: 80px;
      padding-bottom: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .text {
    //padding-top: 80px;
    padding-right: ${props => props.offset ? props.offset + 'px' : ''};

    h3 {
      padding-bottom: 40px;
      color: ${props => props.black ? '#FFFFFF' : ''};
    }
    p {
      padding-bottom: 30px;
      color: ${props => props.black ? '#FFFFFF' : ''};
    }
  }
  .image {
    position: relative;
    padding-top: calc(700 / 683 * 100%);
  }

  /* Normal desktop :992px. */
  @media (min-width: 992px) and (max-width: 1200px) {
    .text {
      padding-top: unset;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .text {
      padding-bottom: 60px;
        margin-left: 15px;
        pl15
    }


      .image{
          padding-top: calc(470 / 683 * 100%);
      }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }
    .offset-lg-1 {
      padding-left: 15px;
    }
    .text {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 60px;
    }
    .image {
      position: relative;
      padding-top: calc(380 / 375 * 100%);
    }
  }
`;



export default AboutComponentReverse;
