import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "./Button";
import {Img} from "./Img";
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";


const Slide = ({offset, data, hideBtn}) => {
    const nextsRef = useRef()
    const prevsRef = useRef()

    return (
        <StyledComponent offset={offset}>
            <Row>
                <Col lg={6} md={12} className={'pr-0'}>
                    {
                        data && <Swiper
                            Swiper
                            modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                            loop={true}
                            // autoplay={{
                            //     delay: 12000,
                            //     disableOnInteraction: false,
                            // }}
                            Draggable={false}
                            // autoplay={false}
                            allowTouchMove={false}
                            slidesPerView={1}
                            initialSlide={1}
                            pagination={false}
                            navigation={{
                                prevEl: prevsRef.current,
                                nextEl: nextsRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevsRef.current;
                                swiper.params.navigation.nextEl = nextsRef.current;
                            }}
                            speed={900}
                        >
                            {
                                data?.map((item) => {
                                    return (
                                        <SwiperSlide key={item?.id}>
                                            <div className="image">
                                                <Img src={item?.full_path}/>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                </Col>
                <Col lg={{offset: 1, span: 5}} md={12} className={'text-wrap'}>
                    <div className="arrow-navbar">
                        <div className="left-arrow arrow hover" ref={prevsRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="9.414"
                                 viewBox="0 0 11.207 9.414">
                                <line id="Line_3886" data-name="Line 3886" x1="10" transform="translate(0.707 4.707)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3887" data-name="Line 3887" x1="4" y2="4"
                                      transform="translate(0.707 0.707)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3888" data-name="Line 3888" x1="4" y1="4"
                                      transform="translate(0.707 4.707)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </svg>

                        </div>
                        <div className="right-arrow arrow hover" ref={nextsRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="9.414"
                                 viewBox="0 0 11.207 9.414">
                                <line id="Line_3886" data-name="Line 3886" x2="10" transform="translate(0.5 4.707)"
                                      fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3887" data-name="Line 3887" x2="4" y2="4"
                                      transform="translate(6.5 0.707)" fill="none" stroke="#fff" stroke-linecap="round"
                                      stroke-width="1"/>
                                <line id="Line_3888" data-name="Line 3888" y1="4" x2="4"
                                      transform="translate(6.5 4.707)" fill="none" stroke="#fff" stroke-linecap="round"
                                      stroke-width="1"/>
                            </svg>


                        </div>
                    </div>
                    {
                        data && <Swiper
                            Swiper
                            modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                            loop={true}
                            // autoplay={{
                            //     delay: 12000,
                            //     disableOnInteraction: false,
                            // }}
                            draggable={false}
                            slidesPerView={1}
                            initialSlide={1}
                            pagination={false}
                            allowTouchMove={false}
                            navigation={{
                                prevEl: prevsRef.current,
                                nextEl: nextsRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevsRef.current;
                                swiper.params.navigation.nextEl = nextsRef.current;
                            }}
                            speed={900}
                        >
                            {
                                data?.map((item) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="text">
                                                <h3>{reactHtmlParser(item?.short_title)}</h3>
                                                <p>{reactHtmlParser(item?.short_desc)}</p>
                                                {hideBtn ? '' : <Button src={`${item?.url}`} text={'Explore'}
                                                                        hoverBackground={'#FFFFFF'} color={'#FFFFFF'}
                                                                        background={'transparent'} hoverColor={'#000000'}
                                                                        stroke={'#000000'}
                                                                        borderColor={'#FFFFFF'}/>}
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }

                </Col>
            </Row>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #000;

    .text-wrap {
        padding-right: ${props => props.offset ? props.offset + 'px' : ''};
    }

    h3, p {
        color: #FFFFFF;
    }

    .left-arrow, .right-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .arrow-navbar {
        display: flex;
        width: 100%;
        -webkit-box-pack: end;
        justify-content: end;
        gap: 20px;
        position: relative;
        top: 130px;
        z-index: 9999;
    }

    .arrow {
        height: 43px;
        width: 43px;
        border: 1px solid #fff;
        border-radius: 50%;
        text-align: right;
    }

    .col-md-6 {
        padding-right: unset;
    }

    .text {
        padding-top: 80px;

        h3 {
            padding-bottom: 40px;
        }

        p {
            padding-bottom: 60px;
        }
    }

    .image {
        position: relative;
        padding-top: calc(700 / 683 * 100%);
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        .arrow-navbar {
            top: 50px;
        }

        h3, p {
            padding-bottom: unset !important;
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        .text {
            padding-top: unset;
        }

        .row {
            flex-direction: column-reverse;
        }

        .text-wrap {
            margin-left: 30px;
            padding-bottom: 30px;
        }

        .arrow-navbar {
            top: 60px;
            right: 30px;
        }

        p {
            padding-right: 30px;
        }
        
        .image{
            padding-top: calc(450 / 680 * 100%) !important;
        }
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        .text {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 60px;
            padding-top: 80px;

            p {
                padding-bottom: 30px;
                margin-bottom: unset;
            }
        }

        .text-wrap {
            padding-right: 30px !important;
        }

        .row {
            flex-direction: column-reverse;
        }

        .arrow-navbar {
            top: 50px;
            left: 15px;
            justify-content: flex-start;
        }

        .image {
            position: relative;
            padding-top: calc(380 / 375 * 100%);
        }
    }
`;


export default Slide;
