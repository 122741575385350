import {useLocation} from "react-router-dom";
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {ScrollSmoother} from "gsap/ScrollSmoother";


// page imports

import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Sticky from "./components/Sticky";

import "react-modal-video/css/modal-video.min.css";
import "simplebar-react/dist/simplebar.min.css";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "./components/Menu";
import FooterForm from "./components/FooterForm";
import Footer from "./components/Footer";
import Router from "./router/Router";
import PageLoader from "./components/preload/PageLoader";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchSocial} from "./api/redux/footer";


function App() {
    const [offset, setOffset] = useState('90');
    const location = useLocation();
    const {pathname} = useLocation();

    //api call
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL_SETTING;

        dispath(fetchSocial([api_url]))
    }, [])

    const getFooterData = useSelector((state) => state.footer);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
        });
        return () => {
            smoother.kill();
        };
    }, []);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === "/") {
            document.body.classList.add("in-home-page");
        } else {
            document.body.classList.remove("in-home-page");
        }

        window.scroll(0, 0);
    }, [location.pathname]);

    // add background color to update page menu
    useEffect(() => {
        if (location.pathname.startsWith('/updates/')) {
            const header = document.querySelector('header');
            header.style.backgroundColor = '#000000';
        } else {
            const header = document.querySelector('header');
            header.style.backgroundColor = '';
        }
    }, [location.pathname])

    // animation functions
    ImageReveal();
    Parallax();
    ParallaxImg();
    SplitUp();
    // page transition
    PageTransition();

    useEffect(() => {
        const updateOffset = () => {
            if (window.innerWidth > 767) {
                const container = document.querySelector('.container');
                if (container) {
                    setOffset(container.offsetLeft + 15);
                }
            }
        };

        // Add an event listener for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', updateOffset);

        // Add event listener to update offset on window resize
        window.addEventListener('resize', updateOffset);
        window.addEventListener('load', updateOffset);

        return () => {
            document.removeEventListener('DOMContentLoaded', updateOffset);
            window.removeEventListener('resize', updateOffset);
            window.removeEventListener('load', updateOffset);
        };
    }, []);

    return (
        <>
            <GlobalStyle/>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                closeOnClick
                hideProgressBar={true}
            />

            <Menu data={getFooterData}/>
            {location.pathname === "/" && <PageLoader/>}
            {location.pathname === "/csr" && <PageLoader/>}
            {location.pathname === "/our-story" && <PageLoader/>}
            {location.pathname === "/mission-vision" && <PageLoader/>}
            {location.pathname === "/board-of-directors" && <PageLoader/>}
            {location.pathname === "/career" && <PageLoader/>}
            {location.pathname === "/team" && <PageLoader/>}
            {location.pathname === "/concern/lanco-agro" && <PageLoader/>}
            {location.pathname === "/concern/lanco-developments" && <PageLoader/>}
            {location.pathname === "/concern/lanco-foundation" && <PageLoader/>}
            {location.pathname === "/updates" && <PageLoader/>}
            {location.pathname === "/contact" && <PageLoader/>}
            {location.pathname.startsWith('/updates/') && <PageLoader/>}

            <Sticky/>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Router/>
                    <FooterForm/>
                    <Footer data={getFooterData}/>
                </div>
            </div>
        </>
    );
}

export default App;
