import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({mission, vision}) => {
    return (
        <StyledComponent>
            <div className="shape">
                <svg xmlns="http://www.w3.org/2000/svg" width="115" height="195" viewBox="0 0 115 195">
                    <text id="_" data-name="&amp;" transform="translate(0 150)" font-size="164" font-family="Cammron-Regular, Cammron" opacity="0.3"><tspan x="0" y="0">&amp;</tspan></text>
                </svg>

            </div>
            <Container>
                <Row className={'mission'}>
                    <Col lg={5} md={6} xs={12} >
                        <div className="mission__text">
                            <h3>{mission?.section_data?.subtitle}</h3>
                            <p>{reactHtmlParser(mission?.section_data?.description)}</p>
                        </div>
                    </Col>
                    <Col lg={{offset: 2, span: 5}} md={6} xs={12} >
                        <div className="mission__img">
                            <div className="mission__img__box">

                            </div>
                            <div className="mission__img__img">
                                <img src={mission?.images?.list?.[0]?.full_path} alt="mission"/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={'vision'}>
                    <Col lg={5} md={6} xs={12} >
                        <div className="vision__img">
                            <div className="vision__img__box">

                            </div>
                            <div className="vision__img__img">
                                <img src={vision?.images?.list?.[0]?.full_path} alt="vision"/>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{offset: 1, span: 5}} md={6} xs={12} >
                        <div className="vision__text">
                            <h3>{vision?.section_data?.subtitle}</h3>
                            <p>{reactHtmlParser(vision?.section_data?.description)}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 150px 0px;
  position: relative;
  
  .shape {
    position: absolute;
    content: '';
    inset: 0;
    display: grid;
    place-content: center;
  }

  .mission {
    &__text {
      h3 {
        margin-bottom: 40px;
      }
    }

    &__img {
      position: relative;
      height: 470px;

      &__box {
        height: 400px;
        width: 400px;
        border: 1px solid #707070;
        position: absolute;
        content: '';
        right: 0;
        top: 0;
      }

      &__img {
        position: absolute;
        content: '';
        top: 70px;
        right: 70px;
        height: 400px;
        width: 400px;
      }
    }
  }

  .vision {
    position: relative;
    height: 470px;
    
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: end;
      margin-top: auto;
      height: 100%;
      h3 {
        margin-bottom: 40px;
      }
    }

    &__img {
      position: relative;
      &__box {
        height: 400px;
        width: 400px;
        border: 1px solid #707070;
        position: absolute;
        content: '';
        left: 0;
        top: 0;
      }

      &__img {
        position: absolute;
        content: '';
        top: 70px;
        left: 70px;
        height: 400px;
        width: 400px;
      }
    }
  }

  /* Normal desktop :992px. */
  @media (min-width: 992px) and (max-width: 1200px) {
    .vision__img__img {
      left: 30px;
      top: 30px;
    }
    .mission__img__img {
     top: 30px;
      right: 30px;
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .shape {
      display: none;
    }
    .mission__img__img {
      top: 0;
      right: 0px;
      width: 100%;
    }
    .vision {
      &__text {
        display: unset;
      }
      &__img {
        &__img {
          top: 0;
          left: 0px;
          width: 100%;
        }
      }
    }
    .mission__img__box {
      display: none;
    }
    .vision__img__box {
      display: none;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 40px;
    h3 {
      font-size: 52px!important;
      font-weight: 400;
      line-height: 60px!important;
    }
    .shape {
      display: none;
    }
    .mission__img__box {
      display: none;
    }
    .vision__img__box {
      display: none;
    }
    .mission {
      height: auto;
      &__text {
        p {
          margin-bottom: 40px;
        }
      }
      &__img {
        height: 400px;
        &__img {
          width: 100%;
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }

    .vision {
      flex-direction: column-reverse;
      height: auto;
      &__text {
        p {
          margin-bottom: 40px;
        }
      }
      &__img__img {
        position: unset;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
      }
    }
  }
`;

export default MyComponent;
