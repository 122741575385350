import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Container>
                <Row>
                    <Col sm={{offset: 3, span: 6}}>
                        <ul className={'menu justify-content-center'}>
                            <li>< Link to={'/'}>Home</Link></li>
                            <li>< Link to={'/our-story'}>Our Stories</Link></li>
                            <li>< Link to={'/csr'}>CSR</Link></li>
                            <li>< Link to={'/updates'}>Updates</Link></li>
                            <li>< Link to={'/career'}>Career</Link></li>
                            <li>< Link to={'/contact'}>Contact</Link></li>
                        </ul>
                        <ul className={'menu-mobile'}>
                            <div>
                                <li>< Link to={'/'}>Home</Link></li>
                                <li>< Link to={'/our-story'}>Our Stories</Link></li>
                                <li>< Link to={'/csr'}>CSR</Link></li>
                            </div>
                            <div>
                                <li>< Link to={'/updates'}>Updates</Link></li>
                                <li>< Link to={'/career'}>Career</Link></li>
                                <li>< Link to={'/contact'}>Contact</Link></li>
                            </div>
                        </ul>
                        <ul className={'social-mobile'}>
                            {
                                data?.posts?.data?.facebook &&
                                <li><a target={"_blank"} href={data?.posts?.data?.facebook}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.161" height="17.514"
                                         viewBox="0 0 8.161 17.514">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1014.546,3920.734v-1.509a3.281,3.281,0,0,1,.032-.527,1.025,1.025,0,0,1,.144-.366.586.586,0,0,1,.361-.25,2.612,2.612,0,0,1,.665-.069h1.508V3915h-2.412a3.923,3.923,0,0,0-3.007.994,4.183,4.183,0,0,0-.914,2.928v1.817h-1.807v3.018h1.806v8.757h3.624v-8.757h2.413l.318-3.018Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }
                            {
                                data?.posts?.data?.instagram &&
                                <li><a target={"_blank"} href={data?.posts?.data?.instagram}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path id="Path_5912" data-name="Path 5912"
                                              d="M8,1.442c2.136,0,2.389.008,3.233.046a4.425,4.425,0,0,1,1.485.276,2.479,2.479,0,0,1,.92.6,2.479,2.479,0,0,1,.6.92,4.43,4.43,0,0,1,.276,1.485c.038.844.046,1.1.046,3.233s-.008,2.389-.046,3.233a4.43,4.43,0,0,1-.276,1.485,2.649,2.649,0,0,1-1.518,1.518,4.425,4.425,0,0,1-1.485.276c-.843.038-1.1.046-3.233.046s-2.389-.008-3.233-.046a4.425,4.425,0,0,1-1.485-.276,2.479,2.479,0,0,1-.92-.6,2.479,2.479,0,0,1-.6-.92,4.425,4.425,0,0,1-.276-1.485c-.038-.844-.046-1.1-.046-3.233s.008-2.389.046-3.233a4.425,4.425,0,0,1,.276-1.485,2.479,2.479,0,0,1,.6-.92,2.479,2.479,0,0,1,.92-.6,4.425,4.425,0,0,1,1.485-.276c.844-.038,1.1-.046,3.233-.046M8,0C5.827,0,5.555.009,4.7.048A5.873,5.873,0,0,0,2.76.42a3.921,3.921,0,0,0-1.417.923A3.921,3.921,0,0,0,.42,2.76,5.873,5.873,0,0,0,.048,4.7C.009,5.555,0,5.827,0,8s.009,2.445.048,3.3A5.873,5.873,0,0,0,.42,13.24a3.921,3.921,0,0,0,.923,1.417,3.923,3.923,0,0,0,1.417.923,5.873,5.873,0,0,0,1.942.372C5.555,15.991,5.827,16,8,16s2.445-.009,3.3-.048a5.873,5.873,0,0,0,1.942-.372,4.09,4.09,0,0,0,2.34-2.34,5.873,5.873,0,0,0,.372-1.942c.039-.853.048-1.126.048-3.3s-.009-2.445-.048-3.3A5.873,5.873,0,0,0,15.58,2.76a3.923,3.923,0,0,0-.923-1.417A3.921,3.921,0,0,0,13.24.42,5.873,5.873,0,0,0,11.3.048C10.445.009,10.173,0,8,0"
                                              transform="translate(0 0)" fill="#9b9b9b"/>
                                        <path id="Path_5913" data-name="Path 5913"
                                              d="M24.784,20.676a4.108,4.108,0,1,0,4.108,4.108,4.108,4.108,0,0,0-4.108-4.108m0,6.775a2.667,2.667,0,1,1,2.667-2.667,2.667,2.667,0,0,1-2.667,2.667"
                                              transform="translate(-16.784 -16.784)" fill="#9b9b9b"/>
                                        <path id="Path_5914" data-name="Path 5914"
                                              d="M62.007,15.673a.96.96,0,1,1-.96-.96.96.96,0,0,1,.96.96"
                                              transform="translate(-48.777 -11.943)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }
                            {data?.posts?.data?.linkedin && <li><a target={"_blank"} href="https://bd.linkedin.com/company/lancogroup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.225"
                                     viewBox="0 0 14 13.225">
                                    <path id="LinkedIn"
                                          d="M1101.435,3924.414v5.117h-3v-4.775c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.009,2.009,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.02.028h.02v-.028a2.984,2.984,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.548,1.548,0,1,0,.02-3.084Zm-1.52,13.225h3v-8.924h-3Z"
                                          transform="translate(-1087.435 -3916.306)" fill="#9b9b9b"/>
                                </svg>
                            </a></li>}
                            {
                                data?.posts?.data?.youtube && <li><a target={"_blank"} href={data?.posts?.data?.youtube}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.5" viewBox="0 0 15 10.5">
                                        <path id="Path_29" data-name="Path 29"
                                              d="M1180.9,3919.725c-.542-.64-1.542-.9-3.451-.9h-6.93c-1.953,0-2.969.278-3.509.959-.526.665-.526,1.644-.526,3v2.583c0,2.625.624,3.958,4.035,3.958h6.93c1.656,0,2.573-.23,3.167-.8.609-.579.869-1.525.869-3.163v-2.583C1181.487,3921.354,1181.446,3920.368,1180.9,3919.725Zm-4.786,4.706-3.147,1.636a.484.484,0,0,1-.707-.427v-3.262a.482.482,0,0,1,.232-.411.488.488,0,0,1,.474-.017l3.147,1.625a.481.481,0,0,1,0,.854Z"
                                              transform="translate(-1166.487 -3918.824)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }
                        </ul>
                        <div className="paragraph">
                            <p>Address- <a target={"_blank"} href={data?.posts?.data?.google_map}>{data?.posts?.data?.office_location}</a></p>
                            <p>© {new Date().getFullYear()} Lanco Group. All Rights Reserved. Design & Developed by
                                <a target={"_blank"} href="https://www.dcastalia.com"> Dcastalia</a></p>
                        </div>
                        <ul className={'social'}>
                            {
                                data?.posts?.data?.facebook &&
                                <li><a target={"_blank"} href={data?.posts?.data?.facebook}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.161" height="17.514"
                                         viewBox="0 0 8.161 17.514">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1014.546,3920.734v-1.509a3.281,3.281,0,0,1,.032-.527,1.025,1.025,0,0,1,.144-.366.586.586,0,0,1,.361-.25,2.612,2.612,0,0,1,.665-.069h1.508V3915h-2.412a3.923,3.923,0,0,0-3.007.994,4.183,4.183,0,0,0-.914,2.928v1.817h-1.807v3.018h1.806v8.757h3.624v-8.757h2.413l.318-3.018Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }
                            {
                                data?.posts?.data?.instagram &&
                                <li><a target={"_blank"} href={data?.posts?.data?.instagram}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <path id="Path_5912" data-name="Path 5912"
                                              d="M8,1.442c2.136,0,2.389.008,3.233.046a4.425,4.425,0,0,1,1.485.276,2.479,2.479,0,0,1,.92.6,2.479,2.479,0,0,1,.6.92,4.43,4.43,0,0,1,.276,1.485c.038.844.046,1.1.046,3.233s-.008,2.389-.046,3.233a4.43,4.43,0,0,1-.276,1.485,2.649,2.649,0,0,1-1.518,1.518,4.425,4.425,0,0,1-1.485.276c-.843.038-1.1.046-3.233.046s-2.389-.008-3.233-.046a4.425,4.425,0,0,1-1.485-.276,2.479,2.479,0,0,1-.92-.6,2.479,2.479,0,0,1-.6-.92,4.425,4.425,0,0,1-.276-1.485c-.038-.844-.046-1.1-.046-3.233s.008-2.389.046-3.233a4.425,4.425,0,0,1,.276-1.485,2.479,2.479,0,0,1,.6-.92,2.479,2.479,0,0,1,.92-.6,4.425,4.425,0,0,1,1.485-.276c.844-.038,1.1-.046,3.233-.046M8,0C5.827,0,5.555.009,4.7.048A5.873,5.873,0,0,0,2.76.42a3.921,3.921,0,0,0-1.417.923A3.921,3.921,0,0,0,.42,2.76,5.873,5.873,0,0,0,.048,4.7C.009,5.555,0,5.827,0,8s.009,2.445.048,3.3A5.873,5.873,0,0,0,.42,13.24a3.921,3.921,0,0,0,.923,1.417,3.923,3.923,0,0,0,1.417.923,5.873,5.873,0,0,0,1.942.372C5.555,15.991,5.827,16,8,16s2.445-.009,3.3-.048a5.873,5.873,0,0,0,1.942-.372,4.09,4.09,0,0,0,2.34-2.34,5.873,5.873,0,0,0,.372-1.942c.039-.853.048-1.126.048-3.3s-.009-2.445-.048-3.3A5.873,5.873,0,0,0,15.58,2.76a3.923,3.923,0,0,0-.923-1.417A3.921,3.921,0,0,0,13.24.42,5.873,5.873,0,0,0,11.3.048C10.445.009,10.173,0,8,0"
                                              transform="translate(0 0)" fill="#9b9b9b"/>
                                        <path id="Path_5913" data-name="Path 5913"
                                              d="M24.784,20.676a4.108,4.108,0,1,0,4.108,4.108,4.108,4.108,0,0,0-4.108-4.108m0,6.775a2.667,2.667,0,1,1,2.667-2.667,2.667,2.667,0,0,1-2.667,2.667"
                                              transform="translate(-16.784 -16.784)" fill="#9b9b9b"/>
                                        <path id="Path_5914" data-name="Path 5914"
                                              d="M62.007,15.673a.96.96,0,1,1-.96-.96.96.96,0,0,1,.96.96"
                                              transform="translate(-48.777 -11.943)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }
                            {data?.posts?.data?.linkedin && <li><a target={"_blank"} href="https://bd.linkedin.com/company/lancogroup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.225"
                                     viewBox="0 0 14 13.225">
                                    <path id="LinkedIn"
                                          d="M1101.435,3924.414v5.117h-3v-4.775c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.009,2.009,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.02.028h.02v-.028a2.984,2.984,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.548,1.548,0,1,0,.02-3.084Zm-1.52,13.225h3v-8.924h-3Z"
                                          transform="translate(-1087.435 -3916.306)" fill="#9b9b9b"/>
                                </svg>
                            </a></li>}
                            {
                                data?.posts?.data?.youtube && <li><a target={"_blank"} href={data?.posts?.data?.youtube}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.5" viewBox="0 0 15 10.5">
                                        <path id="Path_29" data-name="Path 29"
                                              d="M1180.9,3919.725c-.542-.64-1.542-.9-3.451-.9h-6.93c-1.953,0-2.969.278-3.509.959-.526.665-.526,1.644-.526,3v2.583c0,2.625.624,3.958,4.035,3.958h6.93c1.656,0,2.573-.23,3.167-.8.609-.579.869-1.525.869-3.163v-2.583C1181.487,3921.354,1181.446,3920.368,1180.9,3919.725Zm-4.786,4.706-3.147,1.636a.484.484,0,0,1-.707-.427v-3.262a.482.482,0,0,1,.232-.411.488.488,0,0,1,.474-.017l3.147,1.625a.481.481,0,0,1,0,.854Z"
                                              transform="translate(-1166.487 -3918.824)" fill="#9b9b9b"/>
                                    </svg>
                                </a></li>
                            }

                        </ul>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #000000;
    padding-top: 100px;
    padding-bottom: 50px;

    ul.menu {
        display: flex;
        gap: 40px;
        padding-bottom: 40px;

        li {
            a {
                color: #9B9B9B;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .menu-mobile {
        display: none;
    }

    .social-mobile {
        display: none;
    }

    p {
        color: rgba(155, 155, 155, 0.6);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        a {
            color: rgba(155, 155, 155, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

        &:last-child {
            color: rgba(155, 155, 155, 0.6);
            font-size: 12px;
            line-height: 16px;
            
            a {
                font-size: 12px;
            }
        }
    }

    .paragraph {
        text-align: center;

        &:last-of-type(1) {
            color: rgba(155, 155, 155, 0.6);
            font-size: 12px;
            line-height: 16px;
        }
    }

    ul.social {
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 30px;
    }


    @media (max-width: 767px) {
        padding: 80px 0px;
        .menu {
            display: none !important;
        }

        ul.social-mobile {
            display: flex !important;
            gap: 30px;
            margin-bottom: 20px;
            margin-top: 60px;
        }

        .paragraph {
            text-align: left;

            p {
                &:nth-last-of-type(2) {
                    margin-bottom: 20px;
                    padding-right: 60px;
                }

                &:nth-last-of-type(1) {
                    margin-bottom: 0px;
                    padding-right: 140px;
                }
            }
        }

        .social {
            display: none !important;
        }

        ul.menu-mobile {
            display: flex;

            li {
                a {
                    color: #9B9B9B;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                }

                margin-bottom: 40px;

                &:nth-last-of-type(1) {
                    margin-bottom: unset;
                }
            }

            div {
                &:first-child {
                    margin-right: 80px;
                }
            }
        }
    }
`;

export default MyComponent;
