import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import {hover} from "../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import gsap from "gsap";

const HomeBannerV2 = ({data}) => {
    const sliderData = data?.posts?.list;
    const [offset, setOffset] = useState();
    const slickRef = useRef();
    const h1Refs = useRef([]);

    useEffect(() => {
        setOffset(document.querySelector(".container").offsetLeft);
    }, []);

    const handleBeforeChange = (oldIndex, newIndex) => {
        const h1 = h1Refs.current[newIndex];
        // gsap.fromTo(h1, {opacity: 0, y: 50}, {opacity: 1, y: 0, duration: 1.5});
    };

    const settings = {
        loop: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        draggable: false,
        // beforeChange: handleBeforeChange
    };

    function RightArrow(props) {
        const {onClick} = props;
        return (
            <Container className="arrow-container">
                <li className={`go-right hover`} onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="9.414" viewBox="0 0 11.207 9.414">
                        <line id="Line_3886" data-name="Line 3886" x2="10" transform="translate(0.5 4.707)" fill="none"
                              stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        <line id="Line_3887" data-name="Line 3887" x2="4" y2="4" transform="translate(6.5 0.707)"
                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        <line id="Line_3888" data-name="Line 3888" y1="4" x2="4" transform="translate(6.5 4.707)"
                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                    </svg>
                </li>
            </Container>
        );
    }

    function LeftArrow(props) {
        const {onClick} = props;
        return (
            <Container className="arrow-container">
                <li className={`go-left hover`} onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="9.414" viewBox="0 0 11.207 9.414">
                        <line id="Line_3886" data-name="Line 3886" x1="10" transform="translate(0.707 4.707)"
                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        <line id="Line_3887" data-name="Line 3887" x1="4" y2="4" transform="translate(0.707 0.707)"
                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                        <line id="Line_3888" data-name="Line 3888" x1="4" y1="4" transform="translate(0.707 4.707)"
                              fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                    </svg>
                </li>
            </Container>
        );
    }

    return (
        <StyledBanner
            offset={offset}
            sliderChangeSpeed={5000}
            className="banner-slider"
        >
            <Slick {...settings} ref={slickRef}>
                {sliderData?.map((item, index) => {
                    const banner = item?.images?.find((f) => f?.banner === "on");
                    return (
                        <div className="banner-slider__single" key={item.id}>
                            <img src={banner?.full_path} alt={banner?.img_alt}/>
                            <div className="banner-slider__single__content">
                                <div className="line"/>
                                <h1
                                    ref={el => (h1Refs.current[index] = el)}
                                    className={'animated-border'}
                                >
                                    {reactHtmlParser(item?.data?.subtitle)}
                                </h1>
                            </div>
                        </div>
                    )
                })}
            </Slick>
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
    height: calc(100vh);
    position: relative;
    background-color: #1C1C1C;

    .banner-slider__single__content {
        position: absolute;
        content: '';
        bottom: 100px;
    }

    .banner-slider__single {
        position: relative !important;
        height: calc(100vh);
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }
        

        @media (max-width: 768px) {
            &__content {
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        .line {
            top: -100px;
            left: ${props => props.offset ? props.offset + 15 + 'px' : '0px'};
            right: 0;
            z-index: 2;
            position: absolute;
            width: 55%;
            height: 1px;
            transition: width ${(props) => props.initialLoad ? props.initialAnimationDuration : props.sliderChangeSpeed}ms cubic-bezier(0.4, 0, 0, 1);

            /* Normal desktop :1200px. */
            @media (min-width: 1200px) and (max-width: 1500px) {
                //left: 15px;
            }


            /* Normal desktop :992px. */
            @media (min-width: 992px) and (max-width: 1200px) {
                left: 15px;
            }


            /* Tablet desktop :768px. */
            @media (min-width: 768px) and (max-width: 991px) {
                top: -120px;
                left: 15px;
            }


            /* small mobile :320px. */
            @media (max-width: 767px) {
                top: -120px;
                left: 0px;
            }

            &:before {
                content: "";
                top: 0;
                bottom: 0px;
                left: 0;
                z-index: 2;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.5);
            }

            &:after {
                content: "";
                width: 20%;
                height: 1px;
                background-color: #FFFFFF;
                position: absolute;
                top: 0;
                bottom: 0px;
                left: 0;
                z-index: 3;
                transition: width ${(props) => props.initialLoad ? props.initialAnimationDuration : props.sliderChangeSpeed}ms cubic-bezier(0.4, 0, 0, 1);
            }
        }

        .line.animate:after {
            width: 100%;
        }

        img {
            transition: all .6s ease !important;
            display: block !important;
            opacity: 1 !important;
            //filter: brightness(0.4);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        h1 {
            position: relative;
            z-index: 5;
            color: rgba(255, 255, 255, 0.85);
            left: ${props => props.offset ? props.offset + 15 + 'px' : ''};
            bottom: 100px;
            width: ${(props) => (props.initialLoad ? 'fit-content' : '100%')}; /* Change width based on initialLoad */
            height: fit-content;
            text-transform: uppercase;
            font-size: 68px;
            font-weight: 400;
            line-height: 80px;
            letter-spacing: 2px;
            text-align: left;
        }

    }

    .slick-dots {
        bottom: 20px;

        li {
            height: 5px;

            button {
                width: 20px;
                height: 5px;
                background-color: #fff;
                opacity: 1;
                border-radius: 5px;
                position: relative;
                padding: 0;

                &:before {
                    width: 0px;
                    height: 100%;
                    left: 0;
                    font-size: 0;
                    background-color: ${hover};
                    opacity: 1;
                    border-radius: 5px;
                }
            }

            &.slick-active {
                button:before {
                    width: 100%;
                    transition: width 4.3s ease;
                }
            }
        }
    }

    .slick-list {
        .slick-slide {
            z-index: 9;
            transition: transform 0.5s ease !important;
        }

        .slick-active {
            @media (max-width: 768px) {
                .banner-slider__single:before {
                    opacity: 1;
                    transition-delay: 0.4s;
                }

                .banner-slider__single__content {
                    opacity: 1;
                    transition-delay: 0.4s;
                }
            }

            a {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            .line:after {
                width: 100%;
            }

            img {
                filter: none;
            }

            &:hover {
                transform: none !important;
            }
        }
    }
    //
    //.slick-slide:not(.slick-active) {
    //    &:hover {
    //        transform: translateX(-150px) !important;
    //    }
    //}
    //
    //.slick-active.transform {
    //    transform: translateX(-50px) !important;
    //}


    .slick-active {
        .banner-slider__single .line:after {
            width: 100%;
        }
    }

    .go-left,
    .go-right {
        position: absolute;
        bottom: 160px;
        z-index: 99;
        cursor: pointer;
        width: 43px;
        height: 43px;
        border: 1px solid #fff;
        border-radius: 50%;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .go-right {
        right: ${(props) => props.offset}px;

        svg {
            g {
                transition: all 0.3s ease;
            }

            transition: 0.7s all ease;

            #Line_61 {
                transition: 0.7s stroke-dasharray ease;
                stroke-dasharray: 40;
            }
        }


        @media (max-width: 767px) {
            right: 15px !important;
        }
    }

    .go-left {
        right: ${(props) => props.offset + 60}px;

        g {
            transition: all 0.3s ease;
        }

        svg {
            transition: 0.7s all ease;

            #Line_61 {
                transition: 0.7s stroke-dasharray ease;
                stroke-dasharray: 40;
            }
        }

        &:hover {
            g {
                opacity: 1;
            }

            svg {
                #Line_61 {
                    transition: 0.7s stroke-dasharray ease;
                    stroke-dasharray: 30;
                }
            }
        }

    }

    @media (min-width: 992px) and (max-width: 1200px) {
        h1 {
            left: 15px !important;
        }
    }

    @media (max-width: 991px) {
        height: 100svh !important;
        .slick-list {
            .slick-slide:not(.slick-active) {
                transform: none !important;
            }

            .slick-slide {
                img {
                    transform: none !important;
                }

                .banner-slider__single a {
                    left: 0 !important;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .banner-slider__single {
            padding: 0 15px;
            align-items: center;
            display: flex !important;

            &__content {
                width: 100%;

                p,
                a {
                    position: relative;
                    left: 0;
                    right: auto;
                    text-align: left;
                    margin-left: initial;
                }

                a {
                    font-size: 50px;
                    line-height: 50px;
                    margin-top: 20px !important;
                    padding-bottom: 20px;
                    display: block;
                    margin-left: initial !important;
                    text-align: left;
                }
            }
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        .banner-slider__single h1 {
            font-size: 60px;
            font-weight: 400;
            line-height: 60px;
            left: 15px;
        }

        .go-right {
            right: ${(props) => props.offset + 15}px;
            bottom: 100px;
        }

        .go-left {
            right: ${(props) => props.offset + 15}px;
        }
    }


    /* small mobile :320px. */
    @media (max-width: 767px) {
        .banner-slider__single h1 {
            font-size: 40px;
            font-weight: 400;
            line-height: 43px;
            text-align: left;
            left: unset;
            padding-right: 50px;
        }

        .go-right {
            right: ${(props) => props.offset + 15}px;
            bottom: 100px;
        }

        .go-left {
            right: 15px !important;
            left: unset;
        }

    }

`;

export default React.memo(HomeBannerV2);
