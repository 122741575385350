import React, { useState } from 'react';
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import reactHtmlParser from "react-html-parser";

// Define the Operational component
const Operational = ({ data }) => {

    // Define state variables using useState
    const [designation, setDesignation] = useState('All');
    const [loading, setLoading] = useState(false);

    // Handle the selection of a location
    let handleLocation = (value) => {
        setDesignation(value);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 400);
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10.086" height="5.751" viewBox="0 0 10.086 5.751">
                    <path id="Path_1882" data-name="Path 1882" d="M0,0H6.132" transform="translate(9.379 0.707) rotate(135)" fill="none" stroke="#161f13" stroke-linecap="round" stroke-width="1"/>
                    <path id="Path_1883" data-name="Path 1883" d="M0,0H6.132" transform="translate(5.043 5.043) rotate(-135)" fill="none" stroke="#161f13" stroke-linecap="round" stroke-width="1"/>
                </svg>

            </components.DropdownIndicator>
        );
    };
    // Custom styles for the Select component
    const customStyles = {
        // Styles for individual options
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#000000' : 'transparent',
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: 'pointer'
        }),
        // Styles for the dropdown menu
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,
        }),
        // Styles for the list of options within the menu
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer',
        }),
        // Styles for the dropdown indicators
        indicatorsContainer: (provided, state) => ({
            ...provided,
            paddingRight: '30px', // Adjust the padding value as needed
        }),
        // Adjust padding-left for the option style to add left padding
        // Customize the option style for hover effect
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? '#000000' : 'transparent',
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            cursor: 'pointer',
            paddingLeft: '35px', // Left padding
            transition: 'background-color 0.2s', // Add a smooth transition for background-color
            "&:hover": {
                backgroundColor: '#F7F3F0', // Add your desired hover background color here
                color: '#000000'
            },
        }),

    };

    // Extract the department names and remove duplicates
    const teamList = data?.posts?.list;
    const selectItems = teamList?.map(item => item?.data?.department);
    const removeDuplicate = Array.from(new Set(selectItems));
    removeDuplicate.unshift("All"); // Add "All" as the default option

    // Create options for the Select component
    let optionsOne = removeDuplicate?.map((item, index) => (
        {
            value: item,
            label: reactHtmlParser(item),
        }
    ));

    // Filter data after select item match
    const filteredData = designation === "All"
        ? teamList
        : teamList?.filter(item => item.data.department === designation);



    // Define a custom DropdownIndicator component

    // Render the component
    return (
        <>
            {/* StyledFilter section */}
            <StyledFilter className="product-filter pt-120">
                <Container>
                    <Row>
                        <Col md={4}>
                            {/* Select component */}
                            <Select
                                components={{ DropdownIndicator }}
                                onChange={op => handleLocation(op.value)}
                                styles={customStyles}
                                className='select-here files'
                                placeholder='DEPARTMENTS'
                                options={optionsOne}
                            />
                        </Col>
                    </Row>
                </Container>
            </StyledFilter>

            {/* StyledOperational section */}
            <StyledOperational className='team-wrap'>
                <Container>
                    {filteredData && filteredData.length > 0 ?
                        <Row>
                            {filteredData && filteredData.length > 0 &&
                                filteredData?.slice(0, 6)?.map((item, index) => {
                                    return (
                                        <Col key={index} sm={4} className='team-single'>
                                            <div className="team-single__img">
                                                {/* Image */}
                                                <img src={item?.images?.[0]?.full_path} alt="" />
                                            </div>
                                            <div className="team-single__content">
                                                {/* Team member title */}
                                                <h5 className={'split-up'}>{item?.data?.title}</h5>
                                                {/* Team member short description */}
                                                <p className={'split-up'}>{item?.data?.short_desc}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row> : ''
                    }
                </Container>
            </StyledOperational>
        </>
    );
};


const StyledFilter = styled.section`
  background-color: #F5F5F5;
  //padding-top: 100px;
  //padding: 42px 0;

  .css-1fdsijx-ValueContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    border-color: #000000 !important;
    //border: none;
    background-color: transparent;
    border-bottom: 1px solid;
    padding-left: 30px !important;
    height: 43px !important;
    border-radius: 30px !important;
    box-shadow: unset !important;
    letter-spacing: 3.2px;
  }

  .css-a3tvvj-menu {
    z-index: 9;
    //z-index: 9;
    text-transform: uppercase;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder {
    color: #313033 !important;
    font-weight: 400 !important;
    font-size: 16px;
    height: 20px !important;
    text-transform: uppercase !important;
  }

  .css-1dimb5e-singleValue {
    color: #000000 !important;
    font-weight: 400;
    font-size: 16px !important;
    cursor: pointer !important;
    text-transform: uppercase !important;
  }

  .css-1jqq78o-placeholder{
    color: #000000;
  }

  .css-yk16xz-control {
    cursor: pointer !important;
  }

  //.css-1hb7zxy-IndicatorsContainer {
  //  padding-right: 30px !important;
  //  //margin: 0 !important;
  //}

  .css-1u9des2-indicatorSeparator {
    display: none;
  }


  @media (max-width: 767px) {
    //padding: 20px 0 10px 0;
    overflow: visible;
    .container {
      overflow: visible;
    }


    .select-here {
      margin-bottom: 30px;
    }

  }


`;

const StyledOperational = styled.section`
  background-color: #F7F3F0;
  padding-top: 60px;


  .team-single {
    margin-bottom: 40px;

    &__img {
      padding-top: calc(470 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.4s ease;
      }

      &:hover {
        img {
          transform: scale(1.1);
          transition: all 0.4s ease;
        }
      }
    }

    &__content {
      h5 {
        text-transform: capitalize;
        color: #000000;
        margin: 20px 0 10px 0;
      }

      p {
        color: rgba(28, 55, 0, 0.5);
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    .css-e5n5hr-IndicatorsContainer {
      padding-right: unset;
    }
    .team-single {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 60px;

      //&:nth-of-type(odd) {
      //  padding-right: 7.5px;
      //}
      //
      //&:nth-of-type(even) {
      //  padding-left: 7.5px;
      //}

      &:last-child {
        margin-bottom: 0;
      }

      &__content {
        h4 {
          //margin: 22px 0 19px 0;
        }

        p {
          font-size: 12px;
          //line-height: 10px;
        }
      }
    }
  }


`;

export default Operational;
