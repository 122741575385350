import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/career/Overview";
import CareerAccordion from "../../components/career/CareerAccordion";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam as ApiParamKey } from "../../api/network/apiParams";
import { fetchHome } from "../../api/redux/home";
import { fetchCareer, fetchCareerDetails } from "../../api/redux/career";
import GetInTouch from "../../components/GetInTouch";

const MyComponent = () => {
  const dispath = useDispatch();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;

    let api_params = {
      [ApiParamKey.type]: "slug",
      [ApiParamKey.value]: "career",
      [ApiParamKey.get_section]: "yes",
      [ApiParamKey.image]: "yes",
      [ApiParamKey.post]: "yes",
      [ApiParamKey.file]: "no",
      [ApiParamKey.gallery]: "no",
    };

    let params = {
      [ApiParamKey.image]: "yes",
    };

    dispath(fetchCareer([api_url, api_params]));

    let api_for_listing = apiEndPoints.JOBLIST;
    dispath(fetchCareerDetails([api_for_listing]));
  }, []);

  //Refactor Data

  const getData = useSelector((state) => state.career);

  const bannerData = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "career-banner"
  );
  const hr = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "hr-philosophy"
  );
  const joblist = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "job-list"
  );

  const accordion = getData?.detail?.data;

  const categoryItemArray = getData?.posts?.job_cat_list;
  const jobListArray = getData?.posts?.job_list;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career | Lanco Group</title>
        <meta
          name="description"
          content="Lanco Group's commitment to excellence, integrity, and sustainability sets it apart as a visionary conglomerate."
        />
      </Helmet>

      <StyledComponent>
        <InnerBanner
          title={bannerData?.section_data?.subtitle}
          img={bannerData?.images?.list?.[0]?.full_path}
          text={bannerData?.section_data?.description}
        />
        <Overview
          title={hr?.section_data?.subtitle}
          text={hr?.section_data?.description}
          btnText={""}
        />
        {joblist && (
          <CareerAccordion
            data={joblist}
            jobCategory={categoryItemArray}
            jobData={jobListArray}
            accordionData={accordion}
          />
        )}
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section``;

export default MyComponent;
