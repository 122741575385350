import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({title, desc, img}) => {
    return (
        <StyledComponent>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="text-mb">
                            <h3>{title}</h3>
                        </div>
                        <div className="image">
                            <Img src={img}/>
                        </div>
                    </Col>
                    <Col md={{offset: 1, span: 4}}>
                        <div className="text">
                            <h3>{title}</h3>
                            {reactHtmlParser(desc)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 120px 0px;
  background: #F9F9F9;

  .text-mb {
    display: none;
  }

  .image {
    position: relative;
    padding-top: calc(390 / 585 * 100%);
  }

  .text {
    h3 {
      margin-bottom: 40px;
    }
  }
    
    @media (max-width: 991px){
        .col-md-6{
            max-width: 100%;
            flex: 0 0 100%;
        }
        .col-md-4{
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0;
            margin-top: 30px;
        }
    }

  @media (max-width: 767px) {
    padding: 80px 0;
    .text {
      padding-top: 40px;
      h3 {
        display: none;
      }
      p {
        padding-top: 40px;
      }
    }
    .text-mb {
      display: block;
      margin-bottom: 40px;
      h3 {
        font-size: 36px;
        font-weight: 400;
        line-height: 40px;
      }
    }
    .image {
      position: relative;
      padding-top: calc(230 / 345 * 100%);
    }
  }
`;

export default MyComponent;
