import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const InnerBanner = ({img, title, text}) => {
    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img banner={true} src={img}/>
            <Container>
                <Row>
                    <Col md={6}>
                        <h3>{reactHtmlParser( title )}</h3>
                        <p>{reactHtmlParser( text )}</p>
                    </Col>
                </Row>

            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(600 / 1366 * 100%);
  position: relative;
    min-height: 100vh;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
  
  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 2;
  }

  h3 {
    color: #FFFFFF;
    z-index: 2;
    margin-bottom: 25px;
  }
  p{
    color: #ffffff;
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

    @media (min-width: 768px) and (max-width: 991px){
        padding-top: calc(680 / 1366 * 100%);
        
        .container{
            bottom: 40px !important;
        }
    }

  @media (max-width: 767px) {
    padding-top: calc(600 / 375 * 100%);

    h3 {
      font-size: 36px;
      line-height: 40px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

export default InnerBanner;
