import React, {useState, useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

const LancoFoundation = ({offset, data}) => {


    return (
        <StyledLancoFoundation className={'pb-200'}>
            <Container fluid style={{paddingRight: offset + 15 + 'px'}}>
                <Row>
                    <Col lg={7} className='home-video '>
                        <div className=" content show-in-mobile">
                            <h4 className={'title'}>{data?.section_data?.title}</h4>
                            {/*<div className="img">*/}
                            {/*    <Img src={'/images/dynamic/LancoFoundationLogo.svg'}/>*/}
                            {/*</div>*/}
                        </div>
                        <div className='home-video__wrapper'>
                            <Img src={data?.images?.list?.[0]?.full_path}/>
                        </div>
                    </Col>

                    <Col lg={{span: 4, offset: 1}} className={'content'}>
                        <div className="content show-in-desktop">
                            <h4 className={'title'}>{data?.section_data?.title}</h4>
                            {/*<Img src={'/images/dynamic/LancoFoundationLogo.svg'}/>*/}
                        </div>
                        <p>{ReactHtmlParser(data?.section_data?.description)}</p>
                        <Button borderColor={'#000000'} color={'#000000'} hoverBackground={'#000000'} text={'Visit'} src={'/concern/lanco-foundation'}/>
                    </Col>
                </Row>
            </Container>
        </StyledLancoFoundation>
    );
};
const StyledLancoFoundation = styled.section`
  background: #FFFFFF;
  padding-top: 150px;

  .container-fluid {
    padding-left: 0;
  }

  .title {
    font-size: 40px;
    line-height: 48px;
    padding: 100px 0;
  }

  .show-in-mobile {
    display: none!important;
  }


  .home-video {
    &__wrapper {
      position: relative;
      padding-top: calc(600 / 798 * 100%);
      overflow: hidden;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
        transform: scale(1.01);
        transition: transform 0.6s ease;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .global-image {
      position: unset !important;
      height: unset !important;
      width: unset !important;
    }

    img {
      position: unset !important;
      width: 70px;
      height: 70px;
    }

    h4 {
      padding: 0px 0px 20px 0;
      margin: 0;
    }

    p {
      padding: 20px 0px 60px 0;
      margin: 0;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {


  }

  @media (max-width: 991px) {
    .container-fluid {
      padding: 0 15px;
    }

    .title {
      font-size: 24px;
      line-height: 36px;
      padding: 40px 0 !important;
    }

    .content {
      //padding: 0;

      h3 {
        //padding: 100px 0px 60px 0;
        //margin: 0;
      }

      p {
        padding: 20px 0px 40px 0;
        margin: 0;
      }
    }

    .home-video {
      padding: 0;

      &__wrapper {
        //padding-top: calc(260 / 375 * 100%);
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding: 80px 0px;
    
    .show-in-desktop {
      display: none!important;
    }
    
    .show-in-mobile {
      display: block !important;
      margin-bottom: 40px;

      .title {
        padding: 0px 15px !important;
        font-size: 36px;
        font-weight: 400;
        line-height: 40px;
      }

      img {
        margin: 10px 0px 0px 15px !important;
      }
    }

    .home-video {
      &__wrapper {
        padding-top: calc(380 / 375 * 100%);
      }
    }

  .show-in-desktop {
    display: none;
  }

  .container-fluid {
    padding-right: unset !important;
  }
}
`;

export default LancoFoundation;
