import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "../Button"
import {Img} from "../Img";

const HomeCsr = ({data}) => {
    return (
        <HomeCsrComponent className=''>
            <div className="img-wrap-main">
                <div className="img-wrap">
                    <Img src={data?.images?.list?.[0]?.full_path} alt={'inspiring'} />
                </div>
            </div>
            <Container>
                <Row>
                    {
                        data && (
                            <Col lg={5}>
                                <h3>
                                    {reactHtmlParser(reactHtmlParser(data?.section_data?.subtitle))}
                                </h3>

                                <Button text={'Explore CSR'} src={'/csr'} hoverBackground={'#FFFFFF'} color={'#FFFFFF'}
                                        background={'transparent'} hoverColor={'#000000'} stroke={'#000000'}
                                        borderColor={'#FFFFFF'}/>

                            </Col>
                        )
                    }

                </Row>
            </Container>
        </HomeCsrComponent>
    );
};

const HomeCsrComponent = styled.section`
  color: #F7F3F0;
  position: relative;

  .img-wrap-main {
    position: relative;
    padding-top: calc(500 / 1366 * 100%);
  }
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  h3 {
    margin: 0 0 60px 0;
  }

  p {
    margin: 0 0 60px 0;

  }

  @media (max-width: 991px) {
    h3 {
      margin: 0 0 40px 0;
    }

    p {
      margin: 0 0 40px 0;
    }
  }
  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
      .img-wrap-main {
          position: relative;
          padding-top: calc(570 / 1366 * 100%);
      }

  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: -80px;
    }
    .img-wrap-main {
      position: relative;
      padding-top: calc(530 / 375 * 100%);
    }

  }
`;

export default React.memo(HomeCsr);
