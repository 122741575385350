import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";

const Overview = ({
                    title,
                    text
                }) => {


    return (
        <StyledOverview className='pt-120 pb-200'>
            <Container>
                <Row>
                    <Col lg={4}>
                        <h3>{reactHtmlParser(title)}</h3>
                    </Col>

                    <Col lg={{span:7, offset:1}}>
                        <p>{reactHtmlParser(text)}</p>
                    </Col>
                </Row>
            </Container>
        </StyledOverview>

    )
};

const StyledOverview = styled.div`
  h3 {
    margin: 0;
  }
  
  p {
    margin: 0;
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px!important;
    padding-bottom: 80px!important;
  }
  @media (max-width: 991px) {
    h3 {
      margin: 0 0 40px 0;
    }

    //p {
    //  margin: 0 0 40px 0;
    //}
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-top: 80px!important;
    padding-bottom: 140px!important;
    .career-title {
      margin-bottom: 80px!important;
    }
  }
 

`;


export default Overview;
