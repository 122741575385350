import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Csr from "../../components/home/Csr";
import {Helmet, HelmetProvider} from "react-helmet-async";
import UpdatesSlider from "../../components/home/UpdatesSlider";
import {fetchHome} from "../../api/redux/home";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchConcern} from "../../api/redux/concern";
import AboutComponent from "../../components/AboutComponent";
import Slide from "../../components/Slide";
import HomeBannerV2 from "../../components/HomeBanner-v2";

const Home = ({offset}) => {
    // api call
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: "slug",
            [ApiParamKey.value]: "home",
            [ApiParamKey.get_section]: "yes",
            [ApiParamKey.image]: "yes",
            [ApiParamKey.post]: "yes",
            [ApiParamKey.file]: "no",
            [ApiParamKey.gallery]: "no",
        };

        dispath(fetchHome([api_url, api_params]));
    }, []);

    //Refactor Data
    const getData = useSelector((state) => state.home);

    const bannerData = getData?.posts?.data?.sections?.find(
        (f) => f?.section_data?.slug === "home-banner"
    );
    const homeOverview = getData?.posts?.data?.sections?.find(
        (f) => f?.section_data?.slug === "lanco-group"
    );
    const slider = getData?.posts?.data?.sections?.find(
        (f) => f?.section_data?.slug === "concern-slider"
    );
    const homeCsr = getData?.posts?.data?.sections?.find(
        (f) => f?.section_data?.slug === "home-csr"
    );

    // api call
    const dispathCon = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ALL_PRODUCTS;

        let api_params = {
            [ApiParamKey.image]: "yes",
            [ApiParamKey.post]: "yes",
            [ApiParamKey.file]: "no",
            [ApiParamKey.gallery]: "no",
        };

        dispathCon(fetchConcern([api_url, api_params]));
    }, []);

    //Refactor Data
    const getConData = useSelector((state) => state.concern);
    const concernActivity = getConData?.posts?.data;
    const latestUpdate = getData?.posts?.recent_updates;
    const sliderData = slider?.images?.list


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lanco Group</title>
                <meta
                    name="description"
                    content="Lanco Group's commitment to excellence, integrity, and sustainability sets it apart as a visionary conglomerate."
                />
            </Helmet>
            <StyledSection>
                <HomeBannerV2 data={bannerData}/>
                <AboutComponent title={homeOverview?.section_data?.subtitle}
                                description={homeOverview?.section_data?.description}
                                img={homeOverview?.images?.list?.[0]?.full_path} offset={offset}/>
                <Slide data={sliderData} offset={offset}/>
                <Csr data={homeCsr} offset={offset}/>
                <UpdatesSlider data={latestUpdate}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section``;
export default Home;
